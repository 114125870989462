import React from "react";
import { graphql } from 'gatsby'
import Layout from "../components/layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RichText } from 'prismic-reactjs';
import GatsbyLink from "../components/gatsby-link";

const DefaultPage = ({data }) => {
  const document = data.prismicPage;
  return (
    <Layout title={RichText.asText(document.data.titel.raw)}>
      <section>
        <Row>
          <Col>
            <h1>{RichText.asText(document.data.titel.raw)}</h1>
            <RichText render={document.data.content.raw} serializeHyperlink={GatsbyLink}/>
          </Col>
        </Row>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      id
      data {
        titel {
          raw
        }
        content {
          raw
        }
      }
    }
  }
`

export default DefaultPage
